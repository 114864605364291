import { Routes, Route } from "react-router-dom";
import ConversionList from "./ConversionList";
import ConversionDetails from "./Conversion";

const Conversions = () => {
  return (
    <Routes>
      <Route path="/" element={<ConversionList />} />
      <Route path="/:conversionId/*" element={<ConversionDetails />} />
    </Routes>
  );
};

export default Conversions;
