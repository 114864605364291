// ** MUI Imports
import { Theme } from "@mui/material/styles";

const table = {
  MuiTableContainer: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        boxShadow: theme.shadows[0],
        borderTopColor: theme.palette.divider
      })
    }
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        textTransform: "uppercase" as const,
        "& .MuiTableCell-head": {
          fontSize: "0.75rem",
          fontWeight: 600,
          letterSpacing: "0.13px"
        }
      }
    }
  },
  MuiTableBody: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        "& .MuiTableCell-body": {
          letterSpacing: "0.25px",
          color: theme.palette.text.secondary,
          "&:not(.MuiTableCell-sizeSmall):not(.MuiTableCell-paddingCheckbox):not(.MuiTableCell-paddingNone)": {
            paddingTop: theme.spacing(3.5),
            paddingBottom: theme.spacing(3.5)
          }
        }
      })
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
        "& .MuiButton-root": {
          textTransform: "uppercase" as const,
          color: theme.palette.text.secondary
        }
      })
    }
  }
};

export default table;
