import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

const Container = styled.div`
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  margin: auto 20px;
`;

const BlankLayout = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default BlankLayout;
