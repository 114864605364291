import { HashRouter, Routes, Route } from "react-router-dom";

import AuthLayout from "$components/AuthLayout";
import BlankLayout from "$components/BlankLayout";

import MainPage from "./components/Main";
import Login from "./components/Login";
import Users from "./components/Lists/Users";
import Conversions from "./components/Lists/Conversions";
import ConfigPanel from "./components/ConfigPanel";
import InsightsPanel from "./components/InsightsPanel";
import Products from "$components/Lists/Products";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<MainPage />} />
          <Route path="/users/*" element={<Users />} />
          <Route path="/conversions/*" element={<Conversions />} />
          {/* <Route path="/otl" element={<OtlList />} /> */}
          {/* <Route path="/recipes/*" element={<Recipes />} /> */}
          <Route path="/products" element={<Products />} />
          <Route path="/configuration/webapp" element={<ConfigPanel tabName="webapp" />} />
          <Route path="/configuration/thresholds" element={<ConfigPanel tabName="thresholds" />} />
          <Route path="/insights" element={<InsightsPanel tabName="workers" />} />
          <Route path="/insights/workers" element={<InsightsPanel tabName="workers" />} />
          <Route path="/insights/queues" element={<InsightsPanel tabName="queues" />} />
        </Route>
        <Route element={<BlankLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
