import SearchTextField from "$components/common/SearchTextField";
import { Button, Stack } from "@mui/material";

const Products = () => {
  return (
    <>
      <Stack sx={{ m: 1 }} direction="row" justifyContent="space-between" alignItems="flex-start">
        <SearchTextField helperText="Search by internal or external id of a product" />
        <Button onClick={() => {}} color="primary" variant="contained">
          Create product
        </Button>
      </Stack>
    </>
  );
};

export default Products;
