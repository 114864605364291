// import { useState } from "react";
// import { useParams } from "react-router-dom";

import Typography from "@mui/material/Typography";
// import CircularProgress from "@mui/material/CircularProgress";
// import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import Chip from "@mui/material/Chip";

// import Tabs from "./Tabs";
// import { ConfirmationDialog } from "$components/common/ConfirmationDialog";

const User = () => {
  return (
    <div>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="h4">email</Typography>
        account stats
      </Stack>
      {/* <Tabs user={data} /> */}
      {/* <Stack direction="row" gap={1} justifyContent="flex-end">
        {(!data.account_status || ["enable", "unban"].includes(data.account_status)) && (
          <>
            <Button variant="contained" color="error" onClick={() => setAction("ban")}>
              Ban
            </Button>
            <Button variant="contained" color="error" onClick={() => setAction("disable")}>
              Disable
            </Button>
          </>
        )}
        {data.account_status === "disable" && (
          <Button variant="contained" color="warning" onClick={() => setAction("enable")}>
            Enable
          </Button>
        )}
        {data.account_status === "ban" && (
          <Button variant="contained" color="warning" onClick={() => setAction("unban")}>
            Unban
          </Button>
        )}
      </Stack> */}
      {/* <ConfirmationDialog
        open={!!action}
        title={`${(action || "").toUpperCase()} user`}
        message={`Are you sure to ${action} this user?`}
        error={!!isChangeUserStatusError}
        isLoading={isChangeUserStatusLoading}
        handleClose={() => setAction(null)}
        onConfirm={changeUserStatus}
      /> */}
    </div>
  );
};

export default User;
