import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { Collapse } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDownRounded";

type Props<T> = {
  rowIndex: number;
  row: T;
  collapseComponent?: (row: T) => React.ReactNode;
  values: ((row: T) => any)[];
  onDelete?: (row: T) => void;
  onEdit?: (row: T) => void;
  onMore?: (row: T) => void;
};

const GenericTableRow = <T extends {}>({
  rowIndex,
  values,
  row,
  collapseComponent,
  onDelete,
  onEdit,
  onMore
}: Props<T>) => {
  const [open, setOpen] = useState(false);
  const hasActionButton = !!(onDelete || onEdit || onMore || collapseComponent);
  const hasCollapsable = !!collapseComponent;
  return (
    <>
      <TableRow
        key={rowIndex}
        sx={
          !hasCollapsable
            ? { "&:last-child td, &:last-child th": { border: 0 } }
            : { "& > *": { borderBottom: "unset" } }
        }
      >
        {values.map((value, index) => (
          <TableCell key={index}>{value(row)}</TableCell>
        ))}
        {hasActionButton && (
          <TableCell>
            <Stack direction="row">
              {onEdit && (
                <IconButton color="info" onClick={() => onEdit(row)}>
                  <EditIcon />
                </IconButton>
              )}
              {onDelete && (
                <IconButton color="error" onClick={() => onDelete(row)}>
                  <DeleteForeverIcon />
                </IconButton>
              )}
              {onMore && (
                <IconButton color="info" onClick={() => onMore(row)}>
                  <ExpandCircleDownRoundedIcon />
                </IconButton>
              )}
              {hasCollapsable && (
                <IconButton color="info" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              )}
            </Stack>
          </TableCell>
        )}
      </TableRow>
      {collapseComponent && (
        <TableRow sx={{ border: "unset" }}>
          <TableCell colSpan={!hasActionButton ? values.length : values.length + 1}>
            <Collapse in={open}>{collapseComponent(row)}</Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default GenericTableRow;
