import { useSearchParams } from "react-router-dom";
// import { useState } from "react";

// import Chip from "@mui/material/Chip";
// import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";

// import { DeleteConversionDialog } from "./DeleteConversionDialog";
// import GenericTable from "$components/common/Table";
// import Loader from "$components/common/Loader";
import SearchTextField from "$components/common/SearchTextField";
// import Pagination from "$components/common/Pagination";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

// import { DEFAULT_ERROR_MESSAGE } from "$constants";
// import { toPascalCase } from "$helpers/toPascalCase";

const ConversionList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const page = +(searchParams.get("page") || 1);
  // const search = searchParams.get("search") || "";
  const filter = searchParams.get("filter") || "none";

  // const [isDeleteConversionDialogOpen, setIsDeleteConversionDialogOpen] = useState(false);

  const handleFilterChange = (event: SelectChangeEvent) => {
    searchParams.set("filter", event.target.value);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  return (
    <div>
      <Stack sx={{ m: 1 }} spacing={1} direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack spacing={1} direction="row">
          <SearchTextField helperText="Search by key and owner" />
          <FormControl size="small">
            <InputLabel id="filter-conversion-label">Filter</InputLabel>
            <Select labelId="filter-conversion-status" value={filter} label="Age" onChange={handleFilterChange}>
              <MenuItem value="none">None</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      {/* <GenericTable
        data={data?.conversions}
        isFetching={isFetching}
        columns={[
          {
            value: "Id",
            sortBy: "conversion_id"
          },
          "User",
          "Name",
          "Key",
          "Created at",
          "Status"
        ]}
        values={[
          (conversion) => (
            <Typography
              component={Link}
              to={`/conversions/${conversion.conversion_id}`}
              sx={{ fontWeight: 500, fontSize: "0.875rem !important" }}
            >
              {conversion.conversion_id}
            </Typography>
          ),
          (conversion) => (
            <Typography
              component={Link}
              to={`/users/${conversion.owner?.user_id}`}
              sx={{ fontWeight: 500, fontSize: "0.875rem !important" }}
            >
              {conversion.owner?.email}
            </Typography>
          ),
          (conversion) => conversion.source_file?.file_name,
          (conversion) => conversion.source_file?.file_key,
          (conversion) =>
            conversion.result_file ? new Date(conversion.result_file.created_on).toLocaleDateString() : "",
          (conversion) => (
            <Chip label={conversion.status || "unknown"} color={StatusColors.get(conversion.status)} size="small" />
          )
        ]}
        onDelete={deleteConversion}
      /> */}
      {/* {data ? <Pagination filteredCount={data.all_count} page={page} /> : null} */}
    </div>
  );
};

export default ConversionList;
