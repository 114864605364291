const mainColor = "58, 53, 65";

export default {
  primary: {
    light: "#9E69FD",
    main: "#595ED1",
    dark: "#804BDF",
    contrastText: "#FFF"
  },
  secondary: {
    light: "#9C9FA4",
    main: "#ffffff",
    dark: "#777B82",
    contrastText: "#FFF"
  },
  success: {
    light: "#6AD01F",
    main: "#78B78C",
    dark: "#4CB200",
    contrastText: "#FFF"
  },
  error: {
    light: "#FF6166",
    main: "#FF4C51",
    dark: "#E04347",
    contrastText: "#FFF"
  },
  warning: {
    light: "#FFCA64",
    main: "#FFB400",
    dark: "#E09E00",
    contrastText: "#FFF"
  },
  info: {
    light: "#32BAFF",
    main: "#16B1FF",
    dark: "#139CE0",
    contrastText: "#FFF"
  },
  text: {
    primary: "#313358",
    secondary: "#8286DD",
    disabled: `rgba(${mainColor}, 0.38)`
  },
  dappler: {
    dark: "#1E1F36",
    purple: "#313358",
    purpleLight: "#4B4E85",
    purpleVeryLight: "#8286DD",
    menu: "#595ED1"
  }
};
