import { createTheme } from "@mui/material/styles";

import palette from "./base/palette";
import typography from "./base/typography";

import list from "./components/List";
import table from "./components/table";

const theme = createTheme({
  typography: { ...typography },
  palette: { ...palette },
  components: {
    ...table,
    ...list
  }
});

export default theme;
