import { Typography } from "@material-ui/core";
// import { useParams } from "react-router-dom";
// import Loader from "$components/common/Loader";
// import SystemLogList from "$components/Lists/SystemLogs";
import { Box } from "@mui/material";
// import Chip from "@mui/material/Chip";
// import { DEFAULT_ERROR_MESSAGE } from "$constants";

const ConversionDetails = () => {
  return (
    <>
      <Box sx={{ m: 1 }}>
        <Typography>11</Typography>
        <Typography>22 </Typography>
        <Typography>33 </Typography>
        <Typography>44 </Typography>
        <Typography>
          <>
            <b>Status: </b>
            {/* <Chip label={data?.status || "unknown"} color={StatusColors.get(data?.status)} size="small" /> */}
          </>
        </Typography>
      </Box>
      {/* <SystemLogList logs={["logs"] || []} /> */}
    </>
  );
};

export default ConversionDetails;
