import axios from "axios";
import { Auth } from "@aws-amplify/auth";
import { createApi, BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";

const baseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      onProgress?: (progressEvent: any) => void;
    },
    any,
    unknown
  > =>
  async ({ url, method, data, params, onProgress }) => {
    try {
      const token = await Auth.currentSession();
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        ...(onProgress
          ? {
              onUploadProgress: onProgress
            }
          : {}),
        headers: {
          Authorization: `Bearer ${token.getAccessToken().getJwtToken()}`
        }
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      console.error(err);
      return {
        // error: (err?.response as AxiosResponse).data?.errors || err?.message || "Something went wrong"
        error: err?.response as AxiosResponse
      };
    }
  };

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery({
    baseUrl: ""
  }),
  tagTypes: ["User", "UserSubscription", "Project"],
  endpoints: (builder) => ({})
});
