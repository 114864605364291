import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import SearchTextField from "$components/common/SearchTextField";

const TrainingThresholds = () => {
  return (
    <div>
      <Stack sx={{ m: 1 }} spacing={1} direction="row" justifyContent="space-between" alignItems="flex-start">
        <SearchTextField helperText="Search by name or description" />
        <Button onClick={() => {}} color="primary" variant="contained">
          Create Threshold
        </Button>
      </Stack>
    </div>
  );
};

export default TrainingThresholds;
