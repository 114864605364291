// ** MUI Imports
import { Theme } from "@mui/material/styles";

export default {
  MuiListItemIcon: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        minWidth: 0,
        marginRight: theme.spacing(2.25),
        color: theme.palette.text.secondary
      })
    }
  },
  MuiListItemAvatar: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        minWidth: 0,
        marginRight: theme.spacing(4)
      })
    }
  },
  // MuiListItemText: {
  //   styleOverrides: {
  //     dense: {
  //       '& .MuiListItemText-primary': {
  //         color: theme.palette.text.primary
  //       }
  //     }
  //   }
  // },
  MuiListSubheader: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        fontWeight: 600,
        textTransform: "uppercase" as const,
        color: theme.palette.text.primary
      })
    }
  }
};
