import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginBottom: theme.spacing(2)
}));

const Logo = () => {
  return (
    <Container>
      <Link to="/">
        <img src="/images/dappler.svg" alt="logo" />
      </Link>
    </Container>
  );
};

export default Logo;
