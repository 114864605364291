import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { useAppSelector, useAppDispatch } from "$hooks/store";
import { closeNotification } from "$stores/notifications";

const Notifications = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications.notifications);

  const close = () => {
    dispatch(closeNotification());
  };

  return (
    <Snackbar open={!!notifications.length} autoHideDuration={6000} onClose={close}>
      <Alert onClose={close} variant="filled" severity="success" sx={{ width: "100%" }}>
        {/* {notifications.length ? notifications[0] : ""} */}
      </Alert>
    </Snackbar>
  );
};

export default Notifications;
