import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import TrainingThresholds from "./TrainingThresholds";

import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import WebAppSettings from "./AccentooSettings";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface Props {
  tabName: string;
}

const ConfigPanel = ({ tabName }: Props) => {
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/configuration/${newValue}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabName} onChange={handleChange}>
          <Tab icon={<AppRegistrationIcon />} iconPosition="start" label="WebApp Settings" value="webapp" />
          <Tab icon={<ModelTrainingIcon />} iconPosition="start" label="Training Thresholds" value="thresholds" />
        </Tabs>
      </Box>
      <TabPanel value={tabName} index="webapp">
        <WebAppSettings />
      </TabPanel>
      <TabPanel value={tabName} index="thresholds">
        <TrainingThresholds />
      </TabPanel>
    </Box>
  );
};

export default ConfigPanel;
