import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import StorageIcon from "@mui/icons-material/Storage";
import QueueIcon from "@mui/icons-material/Queue";
import { useNavigate } from "react-router-dom";
import Workers from "./Lists/Workers";
import QueueList from "./Lists/Queues";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface Props {
  tabName: string;
}

const InsightsPanel = ({ tabName }: Props) => {
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/insights/${newValue}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabName} onChange={handleChange}>
          <Tab icon={<StorageIcon />} iconPosition="start" label="Workers" value="workers" />
          <Tab icon={<QueueIcon />} iconPosition="start" label="Queues" value="queues" />
        </Tabs>
      </Box>
      <TabPanel value={tabName} index="workers">
        <Workers />
      </TabPanel>
      <TabPanel value={tabName} index="queues">
        <QueueList />
      </TabPanel>
    </Box>
  );
};

export default InsightsPanel;
