import { useSearchParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import Loader from "$components/common/Loader";
import GenericTableRow from "./Row";

type Order = "asc" | "desc";

type Column =
  | string
  | {
      value: string;
      sortBy: string;
    };

type Props<T> = {
  data: T[] | undefined;
  columns: Column[];
  isFetching: boolean;
  values: ((row: T) => any)[];
  collapse?: (row: T) => React.ReactNode;
  onDelete?: (row: T) => void;
  onEdit?: (row: T) => void;
  onMore?: (row: T) => void;
};

export default function GenericTable<T extends {}>({
  columns,
  isFetching,
  values,
  data,
  collapse,
  onDelete,
  onEdit,
  onMore
}: Props<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const order: Order = (searchParams.get("order") as Order) || "asc";
  const orderBy = searchParams.get("orderBy") || "";

  const hasActionButton = !!(onDelete || onEdit || onMore || collapse);

  const setOrderBy = (newOrderBy: string) => {
    if (orderBy === newOrderBy) {
      searchParams.set("order", order === "asc" ? "desc" : "asc");
    }
    searchParams.set("orderBy", newOrderBy);
    setSearchParams(searchParams);
  };

  if (isFetching) {
    return <Loader />;
  }

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => {
              const isSortable = typeof column !== "string";
              return (
                <TableCell key={index}>
                  <TableSortLabel
                    active={isSortable && column.sortBy === orderBy}
                    disabled={!isSortable}
                    direction={order}
                    onClick={() => isSortable && setOrderBy(column.sortBy)}
                  >
                    {isSortable ? column.value : column}
                  </TableSortLabel>
                </TableCell>
              );
            })}
            {hasActionButton && <TableCell>Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isFetching &&
            data &&
            data.map((row, rowIndex) => (
              <GenericTableRow
                rowIndex={rowIndex}
                row={row}
                values={values}
                collapseComponent={collapse}
                onDelete={onDelete}
                onEdit={onEdit}
                onMore={onMore}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
