import { Stack } from "@mui/material";
import SearchTextField from "$components/common/SearchTextField";

const WebAppSettings = () => {
  return (
    <div>
      <Stack sx={{ m: 1 }} spacing={1} direction="row" justifyContent="space-between">
        <SearchTextField helperText="Search by name or description" />
      </Stack>
      {/* {data ? <Pagination filteredCount={data.all_count} page={page} /> : null} */}
    </div>
  );
};

export default WebAppSettings;
