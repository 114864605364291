import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import Auth from "@aws-amplify/auth";

import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import AppBar from "$components/AppBar";
import Notifications from "$components/Notifications";

const Container = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex-grow: 1;
  margin: 20px;
`;

const AuthLayout = () => {
  const [logged, setLogged] = useState(false);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const auth = async () => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (e) {
        setPending(false);
        return;
      }

      setLogged(true);
      setPending(false);
    };

    auth();
  }, []);

  if (pending) {
    return (
      <Stack height="100%" justifyContent="center" margin="auto">
        <CircularProgress />
      </Stack>
    );
  }

  if (logged) {
    return (
      <Container>
        <AppBar />
        <Notifications />
        <Content>
          <Outlet />
        </Content>
      </Container>
    );
  }

  return <Navigate to={"/login"} replace />;
};

export default AuthLayout;
