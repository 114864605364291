import Stack from "@mui/material/Stack";

import SearchTextField from "$components/common/SearchTextField";

const Workers = () => {
  return (
    <div>
      <Stack sx={{ m: 1 }} spacing={1} direction="row" justifyContent="space-between">
        <SearchTextField helperText="Search by version, hostname or description" />
      </Stack>
    </div>
  );
};

export default Workers;
