import { ElementType } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { styled } from "@mui/material/styles";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BuildIcon from "@mui/icons-material/Build";

import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import ShuffleOnIcon from "@mui/icons-material/ShuffleOn";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import CategoryIcon from "@mui/icons-material/Category";

import Logo from "./Logo";

const Container = styled("div")({
  width: "260px",
  padding: "20px 0"
});
const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & { component?: ElementType; target?: "_blank" | undefined }
>(({ theme }) => ({
  width: "100%",
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  color: theme.palette.dappler.purpleVeryLight,
  padding: theme.spacing(0.5, 3.5),
  transition: "opacity .25s ease-in-out",
  "&.active, &.active:hover": {
    boxShadow: theme.shadows[3],
    backgroundImage: `linear-gradient(98deg, #C6A7FE, ${theme.palette.primary.main} 94%)`
  },
  "&.active .MuiTypography-root, &.active .MuiSvgIcon-root": {
    color: `${theme.palette.common.white} !important`
  }
}));

const ResponsiveAppBar = () => {
  const menu = [
    {
      active: !!useMatch("/users/*"),
      to: "/users",
      title: "Users",
      icon: PeopleIcon
    },
    // {
    //   active: !!useMatch("/otl/*"),
    //   to: "/otl",
    //   title: "Otl",
    //   icon: ContentCopy
    // },
    // {
    //   active: !!useMatch("/recipes/*"),
    //   to: "/recipes",
    //   title: "Recipes",
    //   icon: CookieRoundedIcon
    // },
    {
      active: !!useMatch("/conversions/*"),
      to: "/conversions",
      title: "Conversions",
      icon: ShuffleOnIcon
    },
    {
      active: !!useMatch("/products/*"),
      to: "/products",
      title: "Products",
      icon: CategoryIcon
    },
    {
      active: !!useMatch("/configuration/*"),
      to: "/configuration/webapp",
      title: "Configuration",
      icon: BuildIcon
    },
    {
      active: !!useMatch("/insights/*"),
      to: "/insights/workers",
      title: "Insights",
      icon: TroubleshootIcon
    }
  ];
  const navigate = useNavigate();

  const signOut = async () => {
    await Auth.signOut();
    navigate("/login");
  };

  return (
    <Container>
      <Logo />
      <List>
        {menu.map((obj) => (
          <Link key={obj.to} to={obj.to}>
            <ListItem disablePadding sx={{ my: 1, px: "0 !important" }}>
              <MenuNavLink className={obj.active ? "active" : ""}>
                <ListItemIcon>
                  <obj.icon />
                </ListItemIcon>
                <ListItemText primary={obj.title} />
              </MenuNavLink>
            </ListItem>
          </Link>
        ))}
        <Divider />
        <ListItem disablePadding sx={{ mt: 1.5, px: "0 !important" }}>
          <MenuNavLink onClick={signOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuNavLink>
        </ListItem>
      </List>
    </Container>
  );
};
export default ResponsiveAppBar;
