import { useSearchParams } from "react-router-dom";

// import Chip from "@mui/material/Chip";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// import { formatStorage } from "$helpers/formatStorage";
import GenericTable from "$components/common/Table";
// import { UserRow } from "$components/common/Table/UserRow";
// import Loader from "$components/common/Loader";
import SearchTextField from "$components/common/SearchTextField";
// import Pagination from "$components/common/Pagination";
// import { DEFAULT_ERROR_MESSAGE } from "$constants";
// import UserQuickActionsDrawer from "./UserQuickActionsDrawer";
// import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

const UserList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const [currentUserId, setCurrentUserId] = useState<number | null>(null);
  // const [isUserQuickActionsDrawerOpen, setIsUserQuickActionsDrawerOpen] = useState(false);
  // const page = +(searchParams.get("page") || 1);
  const filter = searchParams.get("filter") || "none";

  const handleFilterChange = (event: SelectChangeEvent) => {
    searchParams.set("filter", event.target.value);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  return (
    <div>
      <Stack sx={{ m: 1 }} spacing={1} direction="row" justifyContent="space-between">
        <Stack spacing={1} direction="row">
          <SearchTextField helperText="Search by email and nickname" />
          <FormControl size="small">
            <InputLabel id="filter-account-status">Filter</InputLabel>
            <Select labelId="filter-account-status" value={filter} label="Age" onChange={handleFilterChange}>
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="deleted">Deleted</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <GenericTable
        data={["data?.users"]}
        isFetching={false}
        columns={[
          {
            value: "Id",
            sortBy: "user_id"
          },
          {
            value: "Email",
            sortBy: "email"
          },
          "Last sign in",
          "Projects",
          "Conversions",
          "Otls",
          "Teams",
          "Storage",
          "Status"
        ]}
        values={[]}
        onMore={() => {}}
      />
      {/* {data ? <Pagination filteredCount={data.all_count} page={page} /> : null} */}
    </div>
  );
};

export default UserList;
