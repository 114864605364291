import { useSearchParams } from "react-router-dom";
import { useState, useMemo } from "react";
import { debounce } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

interface Props {
  helperText?: string;
}

const SearchTextField = ({ helperText }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const [value, setValue] = useState(search);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(event.target.value);
    debouncedSearchParam(event.target.value);
  };

  const updateSearchParam = (value: string) => {
    searchParams.set("search", value);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  const debouncedSearchParam = useMemo(() => {
    return debounce(updateSearchParam, 1000);
  }, []);

  return (
    <TextField
      label="Search"
      size="small"
      onChange={onChange}
      helperText={helperText}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRoundedIcon sx={{ color: "#8286DD" }} />
          </InputAdornment>
        )
      }}
      variant="outlined"
    />
  );
};

export default SearchTextField;
