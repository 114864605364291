import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type NotificationType = "success" | "error";
type Notification = {
  type: NotificationType;
  message: string;
};

export const notificationStore = createSlice({
  name: "notifications",
  initialState: {
    notifications: [] as Notification[]
  },
  reducers: {
    addNotification: (state, action: PayloadAction<Notification | string>) => {
      if (typeof action.payload === "string") {
        state.notifications = [
          {
            type: "success",
            message: action.payload
          }
        ];
      } else {
        state.notifications = [action.payload];
      }
    },
    addErrorNotification: (state, action: PayloadAction<string>) => {
      state.notifications = [
        {
          type: "error",
          message: action.payload
        }
      ];
    },
    addSuccessNotification: (state, action: PayloadAction<string>) => {
      state.notifications = [
        {
          type: "success",
          message: action.payload
        }
      ];
    },
    closeNotification: (state, action: PayloadAction<void>) => {
      state.notifications = state.notifications.slice(1);
    },
    clearAll: (state, action: PayloadAction<void>) => {
      state.notifications = [];
    }
  }
});

export const { addNotification, addErrorNotification, addSuccessNotification, closeNotification, clearAll } =
  notificationStore.actions;

export default notificationStore.reducer;
