import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import SyncIcon from "@mui/icons-material/Sync";

const QueueList = () => {
  return (
    <div>
      <Stack sx={{ m: 1 }} spacing={1} direction="row-reverse" justifyContent="space-between">
        <Button onClick={() => {}} color="primary" variant="contained">
          <SyncIcon />
        </Button>
      </Stack>
    </div>
  );
};

export default QueueList;
